import "../Css/header.css";
import React, { useEffect, useState, useRef } from "react";
import Prismic from "@prismicio/client";
import { Fade } from "react-awesome-reveal";
import HeaderImage from "../Assets/h_pattern.svg";
import Arrow from "../Assets/AFW.svg";
import Scroll from "../Assets/scroll.svg";
import { Link } from "react-scroll";

function Header() {
  const apiEndpoint = "https://harshitsharma.prismic.io/api/v2";
  const accessToken =
    "MC5ZYUVtbXhNQUFETUFuODZN.77-977-9Ye-_ve-_vX8o77-977-977-977-977-9a--_ve-_ve-_ve-_ve-_ve-_vQITBF5h77-977-977-977-977-977-9TO-_vQ";
  const Client = Prismic.client(apiEndpoint, { accessToken });
  const [caseStudy, setCaseStudy] = useState([]);
  const [isProtected, setIsProtected] = useState(false);
  const [doc, setDoc] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  //case Study

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at("document.type", "morework"),
      { orderings: "[my.morework.order desc]" }
    );
    if (response) {
      setDoc(response.results);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="hero">
      <div className="header-para">
        <div className="text-header">
          {/* <h1>Hey There!</h1> */}
          <Fade delay={1e2} cascade damping={0.3} triggerOnce={false}>
            <h1>Lead Product Designer based in India</h1>
          </Fade>
        </div>
        <div className="header-div">
          <div className="Text2">
            <a href="mailto:harshitsharma34@gmail.com">
              <img
                className="AWF"
                style={{ color: "white", height: "auto", width: "200px" }}
                src={Arrow}
              />
            </a>
            <Fade delay={1e2} cascade damping={0.3} triggerOnce={false}>
              <p>
                Yo, I'm <span>Harshit Sharma</span>, married to digital design
                and... helping digital founders create unique, empathic product
                experiences
              </p>
            </Fade>
          </div>
          <div>
            <Link
              activeClass="active"
              to="work"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h3>Recently Added</h3>
                <h3>View All Work → </h3>
              </span>
            </Link>
            <div className="main-latest-cs">
              <div className="latest-cs">
                <div className="latest-cs-image">
                  <img src={doc[0]?.data.logo} alt={doc[0]?.data.title} />
                </div>
                <span>
                  <h2 className="latest-cs-text">
                    {doc[0]?.data.small_description}
                  </h2>
                  <a
                    className="latest-cs-cta"
                    href={`/case-study/${doc[0]?.data.content_relationship.uid}`}
                  >
                    Check out the Full Case Study →
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
