import React, { useState } from "react";
import "../Css/contact.css";
import copy from "copy-to-clipboard";
import lenskart from "../Assets/lenskart.svg";
import fmo from "../Assets/fmo.svg";
import optty from "../Assets/optty.svg";
import ipay from "../Assets/ipay.svg";
import hcode from "../Assets/hcode.png";
import bg from "../Assets/bg_company.svg";
import animation from "../Assets/footer_animation.svg";

function Contact() {
  const copyToClipboard = () => {
    copy("harshitsharma34@gmail.com");
  };
  const [showStatements, setShowStatements] = useState(false);



  const showDiv = () => {
    setShowStatements(true);
  };

  let allStatements;
  if (showStatements) {
    allStatements = <div className="success-copy">Copied!</div>;
  } else {
    allStatements = <div className="success-copy">Copy</div>;
  }

  return (
    <div
      style={{
        position: "relative",
        backgroundImage:
          "linear-gradient(180deg, #0055ff00 0%, #0055ff2a 130%)",
      }}
    >
      <img className="footer-animation" src={animation}  alt=""/>
      <div className="footer-main">
        <div className="container-div2">
          <img className="bg" src={bg} alt="" />
          <h1>
            Proud to deliver <span style={{ color: "#6899F9" }}>results</span>{" "}
            for
          </h1>
          <div className="company-images">
            <img src={fmo} className="company img4"  alt=""/>
            <img src={optty} className="company img2"  alt=""/>

            <img src={lenskart} className="company img1" alt="" />

            <img src={ipay} className="company img3" alt=""/>

            <img src={hcode} className="company img5" alt=""/>
          </div>
        </div>
        <div className="contact-div">
          <p className="talk">Have Something in Mind?</p>
          <div className="get-in-touch">
            <h2>
              <a  href="mailto:harshitsharma34@gmail.com" style={{textDecoration:"none" ,color:"white"}}>Let's Connect!</a>
            </h2>
            <div
              className="mask"
             
            >
              <span className="email-call">
                <h1>harshitsharma34@gmail.com</h1>
                <p
                  className="button call"
                  onClick={() => {
                    copyToClipboard();
                    showDiv();
                  }}
                >
                  {allStatements}
                </p>

                <h1> or </h1>
                <a className="button copy" href="https://calendly.com/harshitsharma34/30min" style={{textDecoration:"none" ,color:"white"}} target="_blank">Book a Call</a>
              </span>
            </div>

          </div>
          <ul>
            <li>
              <a href="https://harshits.medium.com/" target="_blank" rel="noopener">
                Medium <span className="↗">↗</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/harshitsharma34/"
                target="_blank"
                rel="noopener"
              >
                LinkedIn <span className="↗">↗</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/harshit.shaxma/"
                target="_blank"
                rel="noopener"
              >
                Instagram <span className="↗">↗</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/bruisedwaynne" target="_blank" rel="noopener">
                Twitter <span className="↗">↗</span>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div className="footer">
            <div className="footer-left">
              <p>
                I Designed and Coded this website from scratch © Harshit Sharma.{" "}
              </p>
              <p>Made over a Cup of ☕ and alot of sleepless 🌛 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
